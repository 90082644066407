import { useState } from "react"
import { useNavigate } from 'react-router-dom';
import { apiClient, login } from "../services/apiClient";


const LoginForm = () => {
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')

    const navigate = useNavigate()

    const handleLogin = (e) => {
        e.preventDefault()

        login(email, password).then(res => {
            console.log(res)
            if (res.status === 200) {
                console.log('logging in')
                navigate('/ordering')
            }
        })
    }

    return (
        <>
            <div className="flex items-center justify-center">
                <div className="px-8 py-6 mt-4 text-left bg-white ">
                    <h3 className="text-2xl font-bold text-center">Login</h3>
                    <form onSubmit={e => handleLogin(e)}>
                        <div className="mt-4">
                            <div>
                                <label className="block" htmlFor="email">Email</label>
                                <input
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    // type="email"
                                    type="text"
                                    placeholder="Email"
                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                // required 
                                />
                            </div>
                            <div className="mt-4">
                                <label className="block">Wachtwoord</label>
                                <input
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    type="password"
                                    placeholder="Wachtwoord"
                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                // required 
                                />
                            </div>
                            <div className="flex items-baseline justify-between">
                                <button type="submit" className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">Login</button>
                                <a href="#" className="text-sm text-blue-600 hover:underline">Wachtwoord vergeten?</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default LoginForm