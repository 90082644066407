import { useEffect, useState } from "react"
import { formatEuros, totalCents } from "../utils/finance"
import { getCurrentBalanceCents } from "../services/apiClient"

const OrderSelector = () => {


    const [amountBeer, setAmountBeer] = useState(0)
    const [amountSoda, setAmountSoda] = useState(0)
    const [currentBalanceCents, setCurrentBalanceCents] = useState(0)

    useEffect(() => {
        (async () => {
            const result = await getCurrentBalanceCents()
            setCurrentBalanceCents(result)
        })()

    }, [])

    return (
        <>
            <h1>wil je bier of fris en hoeveel joe thx</h1>
            <h1>Huidig saldo: {formatEuros(currentBalanceCents)}</h1>

            <div key='soda' className="flex">
                <p>Soda</p>
                <p>€0,75</p>

                <button onClick={() => amountSoda > 1 ? setAmountSoda(amountSoda - 1) : setAmountSoda(0)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    -
                </button>

                <input value={amountSoda} onChange={e => setAmountSoda(e.target.value)} type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-24 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="1" />

                <button onClick={() => setAmountSoda(amountSoda + 1)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    +
                </button>
                <p>Totaal: {formatEuros(75*amountSoda)}</p>

            </div>
            <div key='beer' className="flex">
                <p>Beer</p>
                <p>€0,75</p>

                <button onClick={() =>amountBeer > 1 ? setAmountBeer(amountBeer - 1) : setAmountBeer(0)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    -
                </button>

                <input value={amountBeer} onChange={e => setAmountBeer(e.target.value)} type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-24 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="1" />

                <button onClick={() => setAmountBeer(amountBeer + 1)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    +
                </button>
                <p>Totaal: €{((75*amountBeer)/100).toFixed(2).replace(".",",")}</p>

            </div>
            <h1>Saldo na afschrijven: {formatEuros(currentBalanceCents - totalCents([amountSoda*75, amountBeer*75]))}</h1>


            <button type="submit" disabled={((75*amountSoda)+(75*amountBeer)) > currentBalanceCents ? true : false} className="disabled:opacity-50 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>

        </>
    )
}

export default OrderSelector