export const formatEuros = (cents) => {
    var euros = (cents/100).toFixed(2).replace(".",",")
    return `€${euros}`
} 

export const totalCents = (orderlinesCents) => {
    const orderTotal = orderlinesCents.reduce((acc, curr) => {{
        return acc + curr
    }})
    return orderTotal
}