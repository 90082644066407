import { useEffect, useState } from "react"
import { getCurrentUser } from "../services/apiClient"
import { formatEuros } from "../utils/finance"
import Spinner from '../components/Spinner';

const UserDetails = () => {
    const [userInfo, setUserInfo] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        (async () => {
            const result = await getCurrentUser()
            setUserInfo(result.data.data)
            setIsLoaded(true)
        })()

    }, [])

    return (
        <>
            <p>Persoonlijke gegevens</p>
            {isLoaded ?
                <>
                    <p>Naam: {userInfo.name}</p>
                    <p>Email: {userInfo.email}</p>
                    <p>Saldo: {formatEuros(userInfo.balance) }</p>
                    <p>{userInfo.admin === 1 ? 'WOW je bent admin!' : '' }</p>
                </>
                : <Spinner />}
        </>
    )
}

export default UserDetails