import { useEffect, useState } from "react"
import { getCurrentUser } from "../services/apiClient"
import { NavLink } from "react-router-dom";
import Spinner from "../components/Spinner";

const Sidebar = () => {

    const [userInfo, setUserInfo] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        (async () => {
            const result = await getCurrentUser()
            setUserInfo(result.data.data)
            setIsLoaded(true)
        })()

    }, [])

    return (
        <>
            {isLoaded ?
                <>
                    <p>{userInfo.name}</p>
                    {userInfo.statistics.length > 0 ?
                        userInfo.statistics.map((stat, i) => (
                            <p key={i}>{stat.quantity} × {stat.name}</p>
                        ))
                        : <></>}


                    <NavLink className="bg-emerald-500 text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" to='/userdetails'>
                        Details
                    </NavLink>
                    <RandomPicture />
                </>
                : <Spinner />}
        </>
    )
}

const RandomPicture = () => {
    return (
        <div className="bg-indigo-300 ...">
            <img src="https://www.scouting-alblasserdam.nl/random-image " className="object-cover h-48 w-96 ..." />
        </div>
    )
}

export default Sidebar