import { useState, useEffect } from 'react'
import { getCurrentBalanceCents } from "../services/apiClient"

import { formatEuros, totalCents } from "../utils/finance"
import Spinner from './Spinner'


const BalanceIncreaser = () => {

    const [increaseBalanceWith, setIncreaseBalanceWith] = useState(0)
    const [currentBalanceCents, setCurrentBalanceCents] = useState(0)
    const [isLoaded, setIsLoaded] = useState(false)


    useEffect(() => {
        (async () => {
            const result = await getCurrentBalanceCents()
            setCurrentBalanceCents(result)
            setIsLoaded(true)
        })()
    }, [])

    return (
        <>
            <h1>ouwe kasspekker</h1>
            {/* {isLoaded ?
                <> */}
                    <h1>Huidig saldo: <span className={isLoaded ? '' : ' animate-pulse'}>{isLoaded ? formatEuros(currentBalanceCents) : 'Laden'}</span></h1>
                    <div className="flex">
                        <button onClick={() => increaseBalanceWith > 100 ? setIncreaseBalanceWith(increaseBalanceWith - 100) : setIncreaseBalanceWith(0)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                            -
                        </button>

                        <input value={increaseBalanceWith / 100} onChange={e => setIncreaseBalanceWith(e.target.value)} type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-24 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="1" />

                        <button onClick={() => setIncreaseBalanceWith(increaseBalanceWith + 100)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                            +
                        </button>

                    </div>
                    <h1>Saldo na ophogen: {formatEuros(currentBalanceCents + totalCents([increaseBalanceWith]))}</h1>

                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Ophogen</button>
                {/* </>

                : <Spinner />} */}
        </>
    )
}

export default BalanceIncreaser