import { Route, HashRouter as Router, Routes, Navigate } from 'react-router-dom';
import MainPage from './pages/MainPage';
import Footer from './components/Footer';
import OrderingPage from './pages/OrderingPage';
import BarService from './pages/BarServicePage';
import IncreasePage from './pages/IncreasePage';
import UserDetailsPage from './pages/UserDetailsPage';
import LoginForm from './components/LoginForm';
import AuthLayout from './layouts/AuthLayout';
import LoginLayout from './layouts/LoginLayout';
import OrderHistoryPage from './pages/OrderHistoryPage';

const MainRouter = () => {
    return (
        <div className="min-h-full">
            <Router>
                <Routes>

                    <Route path='/login' element={<LoginLayout />}>
                        <Route path='/login' element={<LoginForm />} />
                    </Route>

                    <Route element={<AuthLayout />}>

                        <Route path='/' element={<Navigate to='ordering' />} />

                        <Route path='/ordering' element={<OrderingPage />} />
                        <Route path='/barservice' element={<BarService />} />
                        <Route path='/barservice/ordering/:id' element={<OrderingPage />} />
                        <Route path='/increase' element={<IncreasePage />} />
                        <Route path='/userdetails' element={<UserDetailsPage />} />
                        <Route path='/orderhistory' element={<OrderHistoryPage />} />
                        <Route path='/logout' element={<>deze pagina bestaat nog niet</>} />

                    </Route>
                </Routes>
            </Router>

            <Footer />
        </div>
    )
}

export default MainRouter