import { Navigate, useOutlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import ColumnLayout from "./ColumnLayout"
import Cookies from "universal-cookie";

const cookies = new Cookies();

const AuthLayout = () => {
    const outlet = useOutlet()

    const session = cookies.get('XSRF-TOKEN')
    if (session) {
        return (
            <ColumnLayout>
                {outlet}
                <Sidebar />
            </ColumnLayout>
        )
    } else {
        return <Navigate replace to='/login' />
    }
}

export default AuthLayout