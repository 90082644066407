import axios from 'axios'

const BASE_URL = 'http://localhost'

export const apiClient = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

export const getCurrentUser = () => {
    return apiClient.get('/api/users/me')
}

export const getCurrentBalanceCents = async () => {
    const currentUser = await getCurrentUser()
    return parseInt(currentUser.data.data.balance)
}

export const getUsers = () => {
    return apiClient.get('/api/users')
}

export const login = (email, password) => {
    return apiClient.get('/sanctum/csrf-cookie')
        .then(res => {
            return apiClient.post('/login', {
                email: email,
                password: password
            })
        })
}

export const logout = () => {
    return apiClient.get('/logout')
}

export const increaseBalance = async (amount) => {
    return apiClient.post('/api/users/increase', {
        amount: amount
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export const createOrder = async (order) => {
    return apiClient.post('/api/orders', order)
}

export const requestResetPassword = async (email) => {
    return apiClient.post('/forgot-password', email)
}

export const setResetPassword = async (token, password) => {
    return apiClient.post(`/forgot-password/${token}`, password)
}

export const getOrderHistory = async () => {
    return apiClient.get('/api/orders/history')
}