import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { getUsers } from '../services/apiClient';
import Spinner from './Spinner';

const NameSelector = () => {
    const [filter, setFilter] = useState('');
    const [users, setUsers] = useState([])

    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        (async () => {
            const result = await getUsers()
            console.log(result.data.data)
            setUsers(result.data.data)
            setIsLoaded(true)
        })()
    }, [])

    return (
        <>
            <div className="flex justify-center">
                <input value={filter} onChange={event => setFilter(event.target.value)} name='filter' type="text" placeholder="Name" className="px-8 py-3 w-96 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"></input>
            </div>

            <div className="flex justify-center">
                <ul className="bg-white rounded-lg w-full text-gray-900">
                    {isLoaded ?
                        users.filter(u => u.name.toLowerCase().includes(filter.toLowerCase()))
                            .map(user => (
                                <NavLink to={`/barservice/ordering/${user.id}`} >
                                    <li className="px-6 py-2 border-b border-gray-200 w-full" key={user.name}>{user.name}</li>
                                </NavLink>
                            )) : <Spinner />}
                </ul>
            </div>
        </>
    )


}

export default NameSelector