import { useEffect, useState } from "react"
import { getOrderHistory } from "../services/apiClient"
import Spinner from "./Spinner"

const HistoryList = () => {
    const [orderHistory, setOrderHistory] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        (async () => {
            const result = await getOrderHistory()
            setOrderHistory(result.data.data)
            setIsLoaded(true)
        })()

    }, [])



    return (
        <>
            {isLoaded ?
                <>
                    <h1>Mijn afschrijvingen</h1>
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th scope="col" className="px-6 py-3">Datum</th>
                                <th scope="col" className="px-6 py-3">Beschijving</th>
                                <th scope="col" className="px-6 py-3">Door</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderHistory.length > 0 ?
                                orderHistory.map((order, i) => {
                                    let date = new Date(order.created_at)
                                    let readableDate = `${date.getDay()}-${date.getMonth()}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`

                                    return (
                                        <>
                                            <tr key={i}>
                                                <td>{readableDate}</td>
                                                <td>{order.order_lines[0].quantity} × {order.order_lines[0].name}</td>
                                                <td>{order.done_by_user}</td>
                                            </tr>
                                            {order.order_lines.length > 1 ?
                                                order.order_lines.slice(1).map((line, li) => (
                                                    <tr key={li}>
                                                        <td></td>
                                                        <td>{line.quantity} × {line.name}</td>
                                                        <td></td>
                                                    </tr>
                                                ))
                                                : <></>}
                                        </>
                                    )
                                })
                                : <>no orders</>}
                        </tbody>
                    </table>
                </>

                : <Spinner />}
        </>
    )
}

export default HistoryList